import classNames from 'classnames';
import { Checkbox } from 'components/InteractiveUIControls/Checkbox/Checkbox';
import { type PasswordValidations } from 'components/SettingsModule/contexts/ChangePassword.context';
import { ChangePasswordContext } from 'components/SettingsModule/contexts/ChangePassword.context';
import { useContext } from 'react';

type PasswordRequirementsProps = {
  confirmDisplayPassword: string,
  displayPassword: string,
  isFromSettings: boolean,
};

const isPasswordMatching =
(displayPassword: string, confirmDisplayPassword: string) =>
  displayPassword === confirmDisplayPassword
&& displayPassword.length > 0 && confirmDisplayPassword.length > 0;

export const isPasswordValid =
(passwordValidations: PasswordValidations,
  displayPassword: string, confirmDisplayPassword: string) =>
  Object.values(passwordValidations).every(Boolean)
&& isPasswordMatching(displayPassword, confirmDisplayPassword);

export const PasswordRequirements = ({confirmDisplayPassword,
  displayPassword,
  isFromSettings}: PasswordRequirementsProps) => {
  const {
    passwordValidations,
  } = useContext(ChangePasswordContext);

  const passwordCheckboxCriteria = [
    { selected: passwordValidations.enoughComplex,
      text: 'Should be complex' },
    { selected: passwordValidations.minLength,
      text: 'At least 12 characters' },
    { selected: passwordValidations.hasLowercase,
      text: 'Lower case letters' },
    { selected: passwordValidations.hasUppercase,
      text: 'Upper case letters' },
    { selected: passwordValidations.hasNumber,
      text: 'Number' },
    { selected: passwordValidations.hasSymbol,
      text: 'Symbol' },
    { selected: passwordValidations.hasLatinLetters,
      text: 'Passwords should be in Latin' },
    { selected: isPasswordMatching(displayPassword, confirmDisplayPassword),
      text: 'Passwords should match' },
  ];

  const checkBoxArea = (selected: boolean, index: number, text: string) => <div className='flex items-center gap-[0.5rem]' key={index}>
    <Checkbox
      checkboxClassName='!rounded-[50%] !size-[0.875rem]'
      isGreen={!isFromSettings}
      selected={selected}
    />
    <p className={classNames({
      'text-black-999': !passwordValidations.hasUppercase,
      'text-label-g-300': passwordValidations.hasUppercase && !isFromSettings,
      'text-main-700': passwordValidations.hasUppercase && isFromSettings,
    }, 'm-0 text-font-2 font-normal')}
    >{text}</p>
  </div>;

  return <div className='flex flex-col gap-[0.75rem]'>
    <p className='m-0 text-font-2 font-normal'>Your password must contain:</p>
    {passwordCheckboxCriteria
      .map(({ text,
        selected }, index) =>
        checkBoxArea(selected, index, text),
      )}
  </div>;
};
